import React from "react"
import { Styled, css } from "theme-ui"
import { Stackoverflow, Twitter, Github, Linkedin } from '@icons-pack/react-simple-icons';

const Footer = () => (
  <footer
    css={css({
      mt: 4,
      pt: 3,
    })}
  >
    © {new Date().getFullYear()}{`  `} 
    <Styled.a 
        href="https://twitter.com/pkiddie" 
        target="_blank"
        rel="noopener noreferrer"
        >
        {<Twitter color="#aaa" size={24} />}
    </Styled.a>
    {` `}
    <Styled.a 
        href="https://github.com/pkiddie" 
        target="_blank"
        rel="noopener noreferrer"
        >
        {<Github color="#aaa" size={24} />}
    </Styled.a>
    {` `}
    <Styled.a 
        href="https://stackoverflow.com/users/254150/pkiddie" 
        target="_blank"
        rel="noopener noreferrer"
        >
        {<Stackoverflow color="#aaa" size={24} />}
    </Styled.a>
    {` `}
    <Styled.a 
        href="https://www.linkedin.com/in/paulkiddie/" 
        target="_blank"
        rel="noopener noreferrer"
        >
        {<Linkedin color="#aaa" size={24} />}
    </Styled.a>
  </footer>
)
export default Footer
